// import { ActionCellRenderer } from './ActionCellRenderer';
import { ValueGetterParams } from '@ag-grid-community/core';
import { DataType } from '../components/DataDisplay/ValueFormatter';
import { ColumnProperty } from '../matterSummary/MatterSummaryColumns';

export enum cellClassEnum {
    numberType = 'numberType',
    currencyType = 'currencyType',
    stringType = 'stringType',
    dateTimeType = 'dateTimeType',
    dateType = 'dateType',
    booleanType = 'booleanType',
    actionType = 'actionType',
    imageType = 'imageType',
    currencyWithoutDecimalsType = 'currencyWithoutDecimalsType',
    percentageType = 'percentageType',
    linkType = 'linkType',
    checkboxType = 'checkboxType',
    editorViewType = 'editorViewType',
    durationType = 'durationType',
    chipsType = 'chipsType',
    contextMenuStaffType = 'contextMenuStaffType',
    dueDateType = 'dueDateType',
    timeType = 'timeType',
    ratingType = 'ratingType',
    dateMonthYear = 'dateMonthYear'
}

var menuTabs = ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'];

// tslint:disable-next-line: no-any
export const StaffBillingSummaryAggFuncs: any = {
    // tslint:disable-next-line: no-any
    'weekToDateBudgetBilledVariance': (params: any) => {
        return getCustomAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'weekToDateBudgetCollectionVariance': (params: any) => {
        return getCustomAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'weekToDateBudgetProducedVariance': (params: any) => {
        return getCustomAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'monthToDateBudgetBilledVariance': (params: any) => {
        return getCustomAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'monthToDateBudgetCollectionVariance': (params: any) => {
        return getCustomAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'monthToDateBudgetProducedVariance': (params: any) => {
        return getCustomAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'yearToDateBudgetBilledVariance': (params: any) => {
        return getCustomAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'yearToDateBudgetCollectionVariance': (params: any) => {
        return getCustomAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'yearToDateBudgetProducedVariance': (params: any) => {
        return getCustomAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'weekToDateBillableHours': (params: any) => {
        return getCustomDurationAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'weekToDateNonBillableHours': (params: any) => {
        return getCustomDurationAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'weekToDateBudgetChargeableHours': (params: any) => {
        return getCustomDurationAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'weekToDateBudgetNonChargeableHours': (params: any) => {
        return getCustomDurationAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'monthToDateBillableHours': (params: any) => {
        return getCustomDurationAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'monthToDateNonBillableHours': (params: any) => {
        return getCustomDurationAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'monthToDateBudgetChargeableHours': (params: any) => {
        return getCustomDurationAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'monthToDateBudgetNonChargeableHours': (params: any) => {
        return getCustomDurationAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'yearToDateBillableHours': (params: any) => {
        return getCustomDurationAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'yearToDateNonBillableHours': (params: any) => {
        return getCustomDurationAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'yearToDateBudgetChargeableHours': (params: any) => {
        return getCustomDurationAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'yearToDateBudgetNonChargeableHours': (params: any) => {
        return getCustomDurationAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'monthToDateRealisation': (params: any) => {
        return getCustomAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'yearToDateRealisation': (params: any) => {
        return getCustomAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'monthToDateCollection': (params: any) => {
        return getCustomAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'yearToDateCollection': (params: any) => {
        return getCustomAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'fullFinancialYearBillableHoursBgt': (params: any) => {
        return getCustomDurationAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'fullFinancialYearNonBillableHoursBgt': (params: any) => {
        return getCustomDurationAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'previousMonthYearToDateBudgetBilledVariance': (params: any) => {
        return getCustomAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'previousMonthYearToDateBudgetCollectionVariance': (params: any) => {
        return getCustomAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'previousMonthYearToDateBudgetProducedVariance': (params: any) => {
        return getCustomAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'previousMonthYearToDateBillableHours': (params: any) => {
        return getCustomDurationAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'previousMonthYearToDateNonBillableHours': (params: any) => {
        return getCustomDurationAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'previousMonthYearToDateBudgetChargeableHours': (params: any) => {
        return getCustomDurationAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'previousMonthYearToDateBudgetNonChargeableHours': (params: any) => {
        return getCustomDurationAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'previousMonthToMonthToDateBudgetBilledVariance': (params: any) => {
        return getCustomAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'previousMonthToMonthToDateBudgetCollectionVariance': (params: any) => {
        return getCustomAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'previousMonthToMonthToDateBudgetProducedVariance': (params: any) => {
        return getCustomAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'previousMonthToMonthToDateBillableHours': (params: any) => {
        return getCustomDurationAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'previousMonthToMonthToDateNonBillableHours': (params: any) => {
        return getCustomDurationAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'previousMonthToMonthToDateBudgetChargeableHours': (params: any) => {
        return getCustomDurationAggFuncValue(params);
    },
    // tslint:disable-next-line: no-any
    'previousMonthToMonthToDateBudgetNonChargeableHours': (params: any) => {
        return getCustomDurationAggFuncValue(params);
    },
};

// tslint:disable-next-line: no-any
export const getCustomDurationAggFuncValue = (params: any) => {
    // tslint:disable-next-line: no-console
    console.log('params', params);

    let duration = '';
    if (params && params.length > 0) {        
        let splitTime: string[];
        var hours = 0;
        var minutes = 0;

        var totalHours = 0;
        var totalMinutes = 0;
        var totalMinutesStr = '';
        
        // tslint:disable-next-line: no-any
        params.forEach((value: any) => {
            if (value && value.value && value.value.length > 0) {
                splitTime = value.value.split(':');
                hours = hours + parseInt(splitTime[0], 10);
                minutes = minutes + parseInt(splitTime[1], 10);
            }
        });

        totalHours = hours + Math.trunc(minutes / 60);
        totalMinutes = minutes % 60;

        if (totalMinutes < 10) {
            totalMinutesStr = '0' + totalMinutes.toString();
        } else {
            totalMinutesStr = totalMinutes.toString();
        }

        duration = totalHours.toString() + ':' + totalMinutesStr;

        if (duration === '0:00') {
            duration = '';
        }
        
        return createDurationValueObject(duration);            
    }        
    return duration;
};

// tslint:disable-next-line: no-any
export const getCustomAggFuncValue = (params: any) => {
    let varianceTotal = 0;
    if (params && params.length > 0) {
        let budgetTotal = 0;
        let billedTotal = 0;           
            // tslint:disable-next-line: no-any
        params.forEach((value: any) => {
            if (value) {
                billedTotal += value.value;
                budgetTotal += value.budgetValue;
            }
        });

        if (billedTotal > 0) {
            varianceTotal = (billedTotal / budgetTotal) * 100;
        }
        
        return createValueObject(billedTotal, budgetTotal);            
    }        
    return varianceTotal;
};

export const createValueObject = (value: number, budgetValue: number) => {
    return {
        value: value,
        budgetValue: budgetValue,
        toString: function () {
            return (value && budgetValue ? (value / budgetValue) * 100 : 0).toString();
        },
    };
};

export const createDurationValueObject = (value: string) => {
    return {
        value: value,
        toString: function () {
            return value;
        },
    };
};

// tslint:disable-next-line: no-any
export const weekToDateBudgetBilledVariance = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createValueObject(params.data.weekToDateBilled, params.data.weekToDateBudgetBilled);
    } else {
        return undefined;
    }
};

export const weekToDateBudgetCollectionVariance = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createValueObject(params.data.weekToDateFeesCollected, params.data.weekToDateBudgetCollection);
    } else {
        return undefined;
    }
};

export const weekToDateBudgetProducedVariance = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createValueObject(params.data.weekToDateFeesProduced, params.data.weekToDateBudgetProduced);
    } else {
        return undefined;
    }
};

export const monthToDateBudgetBilledVariance = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createValueObject(params.data.monthToDateBilled, params.data.monthToDateBudgetBilled);
    } else {
        return undefined;
    }
};

export const monthToDateBudgetCollectionVariance = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createValueObject(params.data.monthToDateFeesCollected, params.data.monthToDateBudgetCollection);
    } else {
        return undefined;
    }
};

export const yearToDateRealisation = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createValueObject(params.data.yearToDateBilled, params.data.yearToDateFeesProduced);
    } else {
        return undefined;
    }
};

export const monthToDateCollection = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createValueObject(params.data.monthToDateCollection, params.data.monthToDateBilled);
    } else {
        return undefined;
    }
};

export const yearToDateCollection = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createValueObject(params.data.yearToDateCollection, params.data.yearToDateBilled);
    } else {
        return undefined;
    }
};

export const monthToDateRealisation = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createValueObject(params.data.monthToDateBilled, params.data.monthToDateFeesProduced);
    } else {
        return undefined;
    }
};

export const monthToDateBudgetProducedVariance = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createValueObject(params.data.monthToDateFeesProduced, params.data.monthToDateBudgetProduced);
    } else {
        return undefined;
    }
};

export const yearToDateBudgetBilledVariance = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createValueObject(params.data.yearToDateBilled, params.data.yearToDateBudgetBilled);
    } else {
        return undefined;
    }
};

export const previousMonthYearToDateBudgetBilledVariance = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createValueObject(params.data.previousMonthYearToDateBilled, params.data.previousMonthYearToDateBudgetBilled);
    } else {
        return undefined;
    }
};

export const previousMonthToMonthToDateBudgetBilledVariance = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createValueObject(params.data.previousMonthToMonthToDateBilled, params.data.previousMonthToMonthToDateBudgetBilled);
    } else {
        return undefined;
    }
};

export const yearToDateBudgetCollectionVariance = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createValueObject(params.data.yearToDateFeesCollected, params.data.yearToDateBudgetCollection);
    } else {
        return undefined;
    }
};

export const previousMonthYearToDateBudgetCollectionVariance = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createValueObject(params.data.previousMonthYearToDateFeesCollected, params.data.previousMonthYearToDateBudgetCollection);
    } else {
        return undefined;
    }
};

export const previousMonthToMonthToDateBudgetCollectionVariance = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createValueObject(params.data.previousMonthToMonthToDateFeesCollected, params.data.previousMonthToMonthToDateBudgetCollection);
    } else {
        return undefined;
    }
};

export const yearToDateBudgetProducedVariance = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createValueObject(params.data.yearToDateFeesProduced, params.data.yearToDateBudgetProduced);
    } else {
        return undefined;
    }
};

export const previousMonthYearToDateBudgetProducedVariance = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createValueObject(params.data.previousMonthYearToDateFeesProduced, params.data.previousMonthYearToDateBudgetProduced);
    } else {
        return undefined;
    }
};

export const previousMonthToMonthToDateBudgetProducedVariance = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createValueObject(params.data.previousMonthToMonthToDateFeesProduced, params.data.previousMonthToMonthToDateBudgetProduced);
    } else {
        return undefined;
    }
};

export const weekToDateBillableHours = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createDurationValueObject(params.data.weekToDateBillableHours);
    } else {
        return undefined;
    }
};

export const weekToDateNonBillableHours = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createDurationValueObject(params.data.weekToDateNonBillableHours);
    } else {
        return undefined;
    }
};

export const weekToDateBudgetChargeableHours = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createDurationValueObject(params.data.weekToDateBudgetChargeableHours);
    } else {
        return undefined;
    }
};

export const weekToDateBudgetNonChargeableHours = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createDurationValueObject(params.data.weekToDateBudgetNonChargeableHours);
    } else {
        return undefined;
    }
};

export const monthToDateBillableHours = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createDurationValueObject(params.data.monthToDateBillableHours);
    } else {
        return undefined;
    }
};

export const monthToDateNonBillableHours = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createDurationValueObject(params.data.monthToDateNonBillableHours);
    } else {
        return undefined;
    }
};

export const monthToDateBudgetChargeableHours = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createDurationValueObject(params.data.monthToDateBudgetChargeableHours);
    } else {
        return undefined;
    }
};

export const monthToDateBudgetNonChargeableHours = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createDurationValueObject(params.data.monthToDateBudgetNonChargeableHours);
    } else {
        return undefined;
    }
};

export const yearToDateBillableHours = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createDurationValueObject(params.data.yearToDateBillableHours);
    } else {
        return undefined;
    }
};

export const previousMonthYearToDateBillableHours = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createDurationValueObject(params.data.previousMonthYearToDateBillableHours);
    } else {
        return undefined;
    }
};

export const previousMonthToMonthToDateBillableHours = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createDurationValueObject(params.data.previousMonthToMonthToDateBillableHours);
    } else {
        return undefined;
    }
};

export const yearToDateNonBillableHours = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createDurationValueObject(params.data.yearToDateNonBillableHours);
    } else {
        return undefined;
    }
};

export const previousMonthYearToDateNonBillableHours = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createDurationValueObject(params.data.previousMonthYearToDateNonBillableHours);
    } else {
        return undefined;
    }
};

export const previousMonthToMonthToDateNonBillableHours = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createDurationValueObject(params.data.previousMonthToMonthToDateNonBillableHours);
    } else {
        return undefined;
    }
};

export const yearToDateBudgetChargeableHours = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createDurationValueObject(params.data.yearToDateBudgetChargeableHours);
    } else {
        return undefined;
    }
};

export const previousMonthYearToDateBudgetChargeableHours = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createDurationValueObject(params.data.previousMonthYearToDateBudgetChargeableHours);
    } else {
        return undefined;
    }
};

export const previousMonthToMonthToDateBudgetChargeableHours = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createDurationValueObject(params.data.previousMonthToMonthToDateBudgetChargeableHours);
    } else {
        return undefined;
    }
};

export const yearToDateBudgetNonChargeableHours = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createDurationValueObject(params.data.yearToDateBudgetNonChargeableHours);
    } else {
        return undefined;
    }
};

export const previousMonthYearToDateBudgetNonChargeableHours = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createDurationValueObject(params.data.previousMonthYearToDateBudgetNonChargeableHours);
    } else {
        return undefined;
    }
};

export const previousMonthToMonthToDateBudgetNonChargeableHours = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createDurationValueObject(params.data.previousMonthToMonthToDateBudgetNonChargeableHours);
    } else {
        return undefined;
    }
};

export const fullFinancialYearBillableHoursBgt = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createDurationValueObject(params.data.fullFinancialYearBillableHoursBgt);
    } else {
        return undefined;
    }
};

export const fullFinancialYearNonBillableHoursBgt = (params: ValueGetterParams) => {
    if (!params.node.group && params.data) {
        return createDurationValueObject(params.data.fullFinancialYearNonBillableHoursBgt);
    } else {
        return undefined;
    }
};

export const StaffBillingSummaryColumns: Array<ColumnProperty> = [
    {
        headerName: 'Staff Name',
        field: 'name',
        format: DataType.String, 
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 190,
        menuTabs: menuTabs,
        cellClass: cellClassEnum.stringType
    },
    {
        headerName: 'Email',
        field: 'email',
        format: DataType.String,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 240,
        menuTabs: menuTabs,
        cellClass: cellClassEnum.stringType
    },
    {
        headerName: 'Team',
        field: 'team',
        format: DataType.String,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 240,
        menuTabs: menuTabs,
        cellClass: cellClassEnum.stringType
    },
    {
        headerName: 'Department',
        field: 'department',
        format: DataType.String, 
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 240,
        menuTabs: menuTabs,
        cellClass: cellClassEnum.stringType
    },
    {
        headerName: 'Office',
        field: 'office',
        format: DataType.String, 
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 240,
        menuTabs: menuTabs,
        cellClass: cellClassEnum.stringType
    },
    {
        headerName: 'WTD Fees Billed',
        field: 'weekToDateBilled',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 170,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'WTD Fees Billed Bgt',
        field: 'weekToDateBudgetBilled',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'WTD Fees Billed Bgt %',
        field: 'weekToDateBudgetBilledVariance',
        format: DataType.Percentage,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 210,
        menuTabs: menuTabs,
        aggFunc: 'weekToDateBudgetBilledVariance',
        enableValue: true,
        valueGetter: 'weekToDateBudgetBilledVariance',
        cellClass: cellClassEnum.percentageType
    },
    {
        headerName: 'WTD Fees W/Os',
        field: 'weekToDateWriteOff',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'WTD Fees Coll',
        field: 'weekToDateFeesCollected',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'WTD Fees Coll Bgt',
        field: 'weekToDateBudgetCollection',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'WTD Fees Coll Bgt %',
        field: 'weekToDateBudgetCollectionVariance',
        format: DataType.Percentage,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'weekToDateBudgetCollectionVariance',
        enableValue: true,
        valueGetter: 'weekToDateBudgetCollectionVariance',
        cellClass: cellClassEnum.percentageType
    },
    {
        headerName: 'WTD Debtor W/Os',
        field: 'weekToDateDebtorWriteOff',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'WTD Fees Produced',
        field: 'weekToDateFeesProduced',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'WTD Fees Produced Bgt',
        field: 'weekToDateBudgetProduced',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 240,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'WTD Fees Produced Bgt %',
        field: 'weekToDateBudgetProducedVariance',
        format: DataType.Percentage,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 240,
        menuTabs: menuTabs,
        aggFunc: 'weekToDateBudgetProducedVariance',
        enableValue: true,
        valueGetter: 'weekToDateBudgetProducedVariance',
        cellClass: cellClassEnum.percentageType
    },
    {
        headerName: 'WTD Billable Hrs',
        field: 'weekToDateBillableHours',
        format: DataType.Duration,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'weekToDateBillableHours',
        enableValue: true,
        valueGetter: 'weekToDateBillableHours',
        cellClass: cellClassEnum.durationType
    },
    {
        headerName: 'WTD Non Billable Hrs',
        field: 'weekToDateNonBillableHours',
        format: DataType.Duration,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'weekToDateNonBillableHours',
        enableValue: true,
        valueGetter: 'weekToDateNonBillableHours',
        cellClass: cellClassEnum.durationType
    },
    {
        headerName: 'WTD Billable Bgt Hrs',
        field: 'weekToDateBudgetChargeableHours',
        format: DataType.Duration,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'weekToDateBudgetChargeableHours',
        enableValue: true,
        valueGetter: 'weekToDateBudgetChargeableHours',
        cellClass: cellClassEnum.durationType
    },
    {
        headerName: 'WTD Non Billable Bgt Hrs',
        field: 'weekToDateBudgetNonChargeableHours',
        format: DataType.Duration,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'weekToDateBudgetNonChargeableHours',
        enableValue: true,
        valueGetter: 'weekToDateBudgetNonChargeableHours',
        cellClass: cellClassEnum.durationType
    },
    {
        headerName: 'MTD Fees Billed',
        field: 'monthToDateBilled',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'MTD Fees Billed Bgt',
        field: 'monthToDateBudgetBilled',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 220,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'MTD Fees Billed Bgt %',
        field: 'monthToDateBudgetBilledVariance',
        format: DataType.Percentage,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 220,
        menuTabs: menuTabs,
        aggFunc: 'monthToDateBudgetBilledVariance',
        enableValue: true,
        valueGetter: 'monthToDateBudgetBilledVariance',
        cellClass: cellClassEnum.percentageType
    },
    {
        headerName: 'MTD Fees W/Os',
        field: 'monthToDateWriteOff',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'MTD Fees Coll',
        field: 'monthToDateFeesCollected',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'MTD Fees Coll Bgt',
        field: 'monthToDateBudgetCollection',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 210,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'MTD Fees Coll Bgt %',
        field: 'monthToDateBudgetCollectionVariance',
        format: DataType.Percentage,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 220,
        menuTabs: menuTabs,
        aggFunc: 'monthToDateBudgetCollectionVariance',
        enableValue: true,
        valueGetter: 'monthToDateBudgetCollectionVariance',
        cellClass: cellClassEnum.percentageType
    },
    {
        headerName: 'MTD Debtor W/Os',
        field: 'monthToDateDebtorWriteOff',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 210,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'MTD Fees Produced',
        field: 'monthToDateFeesProduced',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'MTD Fees Produced Bgt',
        field: 'monthToDateBudgetProduced',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 210,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'MTD Fees Produced Bgt %',
        field: 'monthToDateBudgetProducedVariance',
        format: DataType.Percentage,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 210,
        menuTabs: menuTabs,
        aggFunc: 'monthToDateBudgetProducedVariance',
        enableValue: true,
        valueGetter: 'monthToDateBudgetProducedVariance',
        cellClass: cellClassEnum.percentageType
    },
    {
        headerName: 'MTD Billable Hrs',
        field: 'monthToDateBillableHours',
        format: DataType.Duration,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'monthToDateBillableHours',
        enableValue: true,
        valueGetter: 'monthToDateBillableHours',
        cellClass: cellClassEnum.durationType
    },
    {
        headerName: 'MTD Non Billable Hrs',
        field: 'monthToDateNonBillableHours',
        format: DataType.Duration,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'monthToDateNonBillableHours',
        enableValue: true,
        valueGetter: 'monthToDateNonBillableHours',
        cellClass: cellClassEnum.durationType
    },
    {
        headerName: 'MTD Billable Bgt Hrs',
        field: 'monthToDateBudgetChargeableHours',
        format: DataType.Duration,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'monthToDateBudgetChargeableHours',
        enableValue: true,
        valueGetter: 'monthToDateBudgetChargeableHours',
        cellClass: cellClassEnum.durationType
    },
    {
        headerName: 'MTD Non Billable Bgt Hrs',
        field: 'monthToDateBudgetNonChargeableHours',
        format: DataType.Duration,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'monthToDateBudgetNonChargeableHours',
        enableValue: true,
        valueGetter: 'monthToDateBudgetNonChargeableHours',
        cellClass: cellClassEnum.durationType
    },
    {
        headerName: 'YTD Fees Billed',
        field: 'yearToDateBilled',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'YTD Fees Billed Bgt',
        field: 'yearToDateBudgetBilled',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 230,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'YTD Fees Billed Bgt %',
        field: 'yearToDateBudgetBilledVariance',
        format: DataType.Percentage,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 230,
        menuTabs: menuTabs,
        aggFunc: 'yearToDateBudgetBilledVariance',
        enableValue: true,
        valueGetter: 'yearToDateBudgetBilledVariance',
        cellClass: cellClassEnum.percentageType
    },
    {
        headerName: 'YTD Fees W/Os',
        field: 'yearToDateWriteOff',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'YTD Fees Coll',
        field: 'yearToDateFeesCollected',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'YTD Fees Coll Bgt',
        field: 'yearToDateBudgetCollection',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 210,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'YTD Fees Coll Bgt %',
        field: 'yearToDateBudgetCollectionVariance',
        format: DataType.Percentage,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 220,
        menuTabs: menuTabs,
        aggFunc: 'yearToDateBudgetCollectionVariance',
        enableValue: true,
        valueGetter: 'yearToDateBudgetCollectionVariance',
        cellClass: cellClassEnum.percentageType
    },
    {
        headerName: 'YTD Debtor W/Os',
        field: 'yearToDateDebtorWriteOff',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 210,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'YTD Fees Produced',
        field: 'yearToDateFeesProduced',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'YTD Fees Produced Bgt',
        field: 'yearToDateBudgetProduced',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 230,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'YTD Fees Produced Bgt %',
        field: 'yearToDateBudgetProducedVariance',
        format: DataType.Percentage,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 230,
        menuTabs: menuTabs,
        aggFunc: 'yearToDateBudgetProducedVariance',
        enableValue: true,
        valueGetter: 'yearToDateBudgetProducedVariance',
        cellClass: cellClassEnum.percentageType
    },
    {
        headerName: 'YTD Billable Hrs',
        field: 'yearToDateBillableHours',
        format: DataType.Duration,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'yearToDateBillableHours',
        enableValue: true,
        valueGetter: 'yearToDateBillableHours',
        cellClass: cellClassEnum.durationType
    },
    {
        headerName: 'YTD Non Billable Hrs',
        field: 'yearToDateNonBillableHours',
        format: DataType.Duration,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'yearToDateNonBillableHours',
        enableValue: true,
        valueGetter: 'yearToDateNonBillableHours',
        cellClass: cellClassEnum.durationType
    },
    {
        headerName: 'YTD Billable Bgt Hrs',
        field: 'yearToDateBudgetChargeableHours',
        format: DataType.Duration,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'yearToDateBudgetChargeableHours',
        enableValue: true,
        valueGetter: 'yearToDateBudgetChargeableHours',
        cellClass: cellClassEnum.durationType
    },
    {
        headerName: 'YTD Non Billable Bgt Hrs',
        field: 'yearToDateBudgetNonChargeableHours',
        format: DataType.Duration,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'yearToDateBudgetNonChargeableHours',
        enableValue: true,
        valueGetter: 'yearToDateBudgetNonChargeableHours',
        cellClass: cellClassEnum.durationType
    },
    {
        headerName: 'MTD Realisation %',
        field: 'monthToDateRealisation',
        format: DataType.Percentage,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 210,
        menuTabs: menuTabs,
        enableValue: true,
        cellClass: cellClassEnum.percentageType,
        aggFunc: 'monthToDateRealisation',
        valueGetter: 'monthToDateRealisation',

    },
    {
        headerName: 'YTD Realisation %',
        field: 'yearToDateRealisation',
        format: DataType.Percentage,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 210,
        menuTabs: menuTabs,
        enableValue: true,
        cellClass: cellClassEnum.percentageType,
        aggFunc: 'yearToDateRealisation',
        valueGetter: 'yearToDateRealisation',

    },
    {
        headerName: 'YTD Collection %',
        field: 'yearToDateCollection',
        format: DataType.Percentage,
        width: 210,
        enableRowGroup: true,
        suppressSizeToFit: true,
        menuTabs: menuTabs,
        enableValue: true,
        cellClass: cellClassEnum.percentageType,
        aggFunc: 'yearToDateCollection',
        valueGetter: 'yearToDateCollection',
      },
      {
        field: 'monthToDateCollection',
        headerName: 'MTD Collection %',
        format: DataType.Percentage,
        width: 210,
        enableRowGroup: true,
        suppressSizeToFit: true,
        menuTabs: menuTabs,
        cellClass: cellClassEnum.percentageType,
        enableValue: true,
        aggFunc: 'monthToDateCollection',
        valueGetter: 'monthToDateCollection',
    },
    {
        headerName: 'Full Year Fees Recorded Bgt',
        field: 'fullFinancialYearFeesRecordedBgt',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 210,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'Full Year Fees Billed Bgt',
        field: 'fullFinancialYearFeesBilledBgt',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 210,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'Full Year Fees Collected Bgt',
        field: 'fullFinancialYearFeesCollectedBgt',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 210,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'Full Year Billable Hrs Bgt',
        field: 'fullFinancialYearBillableHoursBgt',
        format: DataType.Duration,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'fullFinancialYearBillableHoursBgt',
        enableValue: true,
        valueGetter: 'fullFinancialYearBillableHoursBgt',
        cellClass: cellClassEnum.durationType
    },
    {
        headerName: 'Full Year Non-Billable Hrs Bgt',
        field: 'fullFinancialYearNonBillableHoursBgt',
        format: DataType.Duration,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'fullFinancialYearNonBillableHoursBgt',
        enableValue: true,
        valueGetter: 'fullFinancialYearNonBillableHoursBgt',
        cellClass: cellClassEnum.durationType
    },
    // PYTD
    {
        headerName: 'PYTD Fees Billed',
        field: 'previousMonthYearToDateBilled',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'PYTD Fees Billed Bgt',
        field: 'previousMonthYearToDateBudgetBilled',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'PYTD Fees Produced Bgt %',
        field: 'previousMonthYearToDateBudgetBilledVariance',
        format: DataType.Percentage,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 230,
        menuTabs: menuTabs,
        aggFunc: 'previousMonthYearToDateBudgetBilledVariance',
        enableValue: true,
        valueGetter: 'previousMonthYearToDateBudgetBilledVariance',
        cellClass: cellClassEnum.percentageType
    },
    {
        headerName: 'PYTD Fees W/Os',
        field: 'previousMonthYearToDateWriteOff',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'PYTD Fees Coll',
        field: 'previousMonthYearToDateFeesCollected',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'PYTD Fees Coll Bgt',
        field: 'previousMonthYearToDateBudgetCollection',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 210,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'PYTD Fees Coll Bgt %',
        field: 'previousMonthYearToDateBudgetCollectionVariance',
        format: DataType.Percentage,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 220,
        menuTabs: menuTabs,
        aggFunc: 'previousMonthYearToDateBudgetCollectionVariance',
        enableValue: true,
        valueGetter: 'previousMonthYearToDateBudgetCollectionVariance',
        cellClass: cellClassEnum.percentageType
    },
    {
        headerName: 'PYTD Debtor W/Os',
        field: 'previousMonthYearToDateDebtorWriteOff',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 210,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'PYTD Fees Produced',
        field: 'previousMonthYearToDateFeesProduced',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'PYTD Fees Produced Bgt',
        field: 'previousMonthYearToDateBudgetProduced',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 230,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'PYTD Fees Produced Bgt %',
        field: 'previousMonthYearToDateBudgetProducedVariance',
        format: DataType.Percentage,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 230,
        menuTabs: menuTabs,
        aggFunc: 'previousMonthYearToDateBudgetProducedVariance',
        enableValue: true,
        valueGetter: 'previousMonthYearToDateBudgetProducedVariance',
        cellClass: cellClassEnum.percentageType
    },
    {
        headerName: 'PYTD Non Billable Hrs',
        field: 'previousMonthYearToDateBillableHours',
        format: DataType.Duration,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'previousMonthYearToDateBillableHours',
        enableValue: true,
        valueGetter: 'previousMonthYearToDateBillableHours',
        cellClass: cellClassEnum.durationType
    },
    {
        headerName: 'PYTD Non Billable Hrs',
        field: 'previousMonthYearToDateNonBillableHours',
        format: DataType.Duration,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'previousMonthYearToDateNonBillableHours',
        enableValue: true,
        valueGetter: 'previousMonthYearToDateNonBillableHours',
        cellClass: cellClassEnum.durationType
    },
    {
        headerName: 'PYTD Billable Bgt Hrs',
        field: 'previousMonthYearToDateBudgetChargeableHours',
        format: DataType.Duration,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'previousMonthYearToDateBudgetChargeableHours',
        enableValue: true,
        valueGetter: 'previousMonthYearToDateBudgetChargeableHours',
        cellClass: cellClassEnum.durationType
    },
    {
        headerName: 'PYTD Non Billable Bgt Hrs',
        field: 'previousMonthYearToDateBudgetNonChargeableHours',
        format: DataType.Duration,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'previousMonthYearToDateBudgetNonChargeableHours',
        enableValue: true,
        valueGetter: 'previousMonthYearToDateBudgetNonChargeableHours',
        cellClass: cellClassEnum.durationType
    },
    // PMTD
    {
        headerName: 'PMTD Fees Billed',
        field: 'previousMonthToMonthToDateBilled',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'PMTD Fees Billed Bgt',
        field: 'previousMonthToMonthToDateBudgetBilled',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'PMTD Fees Produced Bgt %',
        field: 'previousMonthToMonthToDateBudgetBilledVariance',
        format: DataType.Percentage,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 230,
        menuTabs: menuTabs,
        aggFunc: 'previousMonthToMonthToDateBudgetBilledVariance',
        enableValue: true,
        valueGetter: 'previousMonthToMonthToDateBudgetBilledVariance',
    cellClass: cellClassEnum.percentageType
    },
    {
        headerName: 'PMTD Fees W/Os',
        field: 'previousMonthToMonthToDateWriteOff',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'PMTD Fees Coll',
        field: 'previousMonthToMonthToDateFeesCollected',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'PMTD Fees Coll Bgt',
        field: 'previousMonthToMonthToDateBudgetCollection',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 210,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'PMTD Fees Coll Bgt %',
        field: 'previousMonthToMonthToDateBudgetCollectionVariance',
        format: DataType.Percentage,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 220,
        menuTabs: menuTabs,
        aggFunc: 'previousMonthToMonthToDateBudgetCollectionVariance',
        enableValue: true,
        valueGetter: 'previousMonthToMonthToDateBudgetCollectionVariance',
        cellClass: cellClassEnum.percentageType
    },
    {
        headerName: 'PMTD Debtor W/Os',
        field: 'previousMonthToMonthToDateDebtorWriteOff',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 210,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'PMTD Fees Produced',
        field: 'previousMonthToMonthToDateFeesProduced',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'PMTD Fees Produced Bgt',
        field: 'previousMonthToMonthToDateBudgetProduced',
        format: DataType.Currency,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 230,
        menuTabs: menuTabs,
        aggFunc: 'sum',
        enableValue: true,
        cellClass: cellClassEnum.currencyType
    },
    {
        headerName: 'PMTD Fees Produced Bgt %',
        field: 'previousMonthToMonthToDateBudgetProducedVariance',
        format: DataType.Percentage,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 230,
        menuTabs: menuTabs,
        aggFunc: 'previousMonthToMonthToDateBudgetProducedVariance',
        enableValue: true,
        valueGetter: 'previousMonthToMonthToDateBudgetProducedVariance',
        cellClass: cellClassEnum.percentageType
    },
    {
        headerName: 'PMTD Billable Hrs',
        field: 'previousMonthToMonthToDateBillableHours',
        format: DataType.Duration,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'previousMonthToMonthToDateBillableHours',
        enableValue: true,
        valueGetter: 'previousMonthToMonthToDateBillableHours',
        cellClass: cellClassEnum.durationType
    },
    {
        headerName: 'PMTD Non Billable Hrs',
        field: 'previousMonthToMonthToDateNonBillableHours',
        format: DataType.Duration,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'previousMonthToMonthToDateNonBillableHours',
        enableValue: true,
        valueGetter: 'previousMonthToMonthToDateNonBillableHours',
        cellClass: cellClassEnum.durationType
    },
    {
        headerName: 'PMTD Billable Bgt Hrs',
        field: 'previousMonthToMonthToDateBudgetChargeableHours',
        format: DataType.Duration,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'previousMonthToMonthToDateBudgetChargeableHours',
        enableValue: true,
        valueGetter: 'previousMonthToMonthToDateBudgetChargeableHours',
        cellClass: cellClassEnum.durationType
    },
    {
        headerName: 'PMTD Non Billable Bgt Hrs',
        field: 'previousMonthToMonthToDateBudgetNonChargeableHours',
        format: DataType.Duration,
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 200,
        menuTabs: menuTabs,
        aggFunc: 'previousMonthToMonthToDateBudgetNonChargeableHours',
        enableValue: true,
        valueGetter: 'previousMonthToMonthToDateBudgetNonChargeableHours',
        cellClass: cellClassEnum.durationType
    },
    {
        headerName: 'Staff Classification',
        field: 'staffClassification',
        format: DataType.String, 
        enableRowGroup: true,
        suppressSizeToFit: true,
        width: 190,
        menuTabs: menuTabs,
        cellClass: cellClassEnum.stringType
    }
];
